/**
 * @file It contains scripts for acknowledgement page.
 */
import ThemisPinInput from "@/components/shared/pin-input"
import ThemisDecision from "@/components/shared/decision"
import ThemisSurvey from "@/components/shared/survey"
import { mapGetters, mapActions } from "vuex"

export default {
  name      : "Acknowledgement",
  components: { ThemisPinInput, ThemisDecision, ThemisSurvey },
  data() {
    return {
      isEmailFormDialogVisible: false,
      email                   : undefined,
      showSurvey              : false,
      surveySent              : false
    }
  },
  computed: {
    ...mapGetters({
      isSubscribedForNotification : "auth/isSubscribedForNotification",
      isSubscribingForNotification: "auth/isSubscribingForNotification",
      report                      : "auth/report",
      reportNumber                : "auth/reportNumber",
      isSurveyAdded               : "survey/isSurveyAdded",
      isAddingSurvey              : "survey/isAddingSurvey"
    }),
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    ...mapActions({
      subscribeForNotification: "auth/subscribeForNotification",
      addSurvey               : "survey/addSurvey"
    }),
    handleClose() {
      this.isEmailFormDialogVisible = false
      this.email                    = undefined
    },
    logout(surveyResponses) {
      if (!this.surveySent && surveyResponses) {
        this.addSurvey({ surveyResponses, isAuthPublic: false })
      }
      this.showSurvey = false
      this.surveySent = false
      this.$router.push({
        name  : "logout",
        params: {
          locale : this.$route.params.locale,
          channel: this.$route.params.channel
        }
      })
    },
    submitSurveyResponse(surveyResponses) {
      this.addSurvey({ surveyResponses, isAuthPublic: false })
      this.surveySent = true
    }
  },
  watch: {
    isSubscribedForNotification: {
      handler: function(newValue) {
        if (newValue) {
          this.handleClose()
          this.$router.push({
            name  : "verify-email",
            params: {
              locale : this.$route.params.locale,
              channel: this.$route.params.channel
            }
          })
        }
      }
    },
    isEmailFormDialogVisible: {
      handler: function(newValue) {
        if(!newValue) {
          this.email = undefined
        }
      }
    }
  }
}