
/**
 * @file It contains scripts for pin input
 */
export default {
  name : "PinInput",
  props: {
    length       : Number,
    value        : String,
    readonly     : Boolean,
    disabled     : Boolean,
    hideDetails  : Boolean,
    errorMessages: Array,
    type         : String,
    size         : Number
  },
  data() {
    return {
      internalValue: new Array(this.length)
    }
  },
  computed: {
    inputStyles() {
      return {
        height: `${this.size}px !important`,
        width : `${this.size}px !important`,
        margin: `${this.size / 5}px`
      }
    }
  },
  methods: {
    /**
     * This method will handle keydown event of all input fields.
     * It will make sure only single digits are entered into input fields.
     * @param {*} event key down event.
     */
    handleKeydown() {
      const keyCode = event.keyCode
      const target  = event.target
      if (keyCode === this.$CONSTANTS.KEY_CODE.BACKSPACE || keyCode === this.$CONSTANTS.KEY_CODE.ARROW_LEFT) {
        if (!target.selectionStart && target.previousSibling) {
          target.previousSibling.focus()
        }
      } else if (keyCode === this.$CONSTANTS.KEY_CODE.ARROW_RIGHT) {
        if ((target.selectionStart || !target.value) && target.nextSibling){
          target.nextSibling.focus()
        }
      } else if (
        (keyCode >= this.$CONSTANTS.KEY_CODE.ZERO && keyCode <= this.$CONSTANTS.KEY_CODE.NINE) ||
        (keyCode >= this.$CONSTANTS.KEY_CODE.NUM_PAD_ZERO && keyCode <= this.$CONSTANTS.KEY_CODE.NUM_PAD_NINE)) {
        if (target.selectionStart && target.nextSibling) {
          target.nextSibling.focus()
        } else {
          target.value = ""
        }
      } else {
        event.preventDefault()
      }
    }
  },
  watch: {
    internalValue: {
      deep   : true,
      handler: function(value) {
        let result = ""
        for(const digit of value) {
          if (!["", undefined, null].includes(digit)) {
            result += digit
          } else {
            this.$emit("input", undefined)
            return
          }
        }
        this.$emit("input", result)
      }
    },
    value: {
      immediate: true,
      handler  : function(value) {
        if (value) {
          const stringValue  = value.toString()
          this.internalValue = stringValue.split("").map(digit => {
            return digit
          })
        }
      }
    }
  }
}